import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=61da1ad0&scoped=true&"
import script from "./SiteHeader.vue?vue&type=script&lang=js&"
export * from "./SiteHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61da1ad0",
  null
  
)

export default component.exports